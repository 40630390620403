import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import principalimg from '../../Assets/img/prinicpal.jpg'

function Feecourse() {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>BACHELOR OF EDUCATION (B.ED)</h2>
              <h3>ELIGIBILITY AND FEE STRUCTURE</h3>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">

           

              <div className="doctor-details-item">
                <div className="doctor-details-right">

                <div className="doctor-details-biography">
                    <h3>ELIGIBILITY FOR ADMISSION</h3>
                    <p> Candidates with at least 50% marks (45% for SC/ST candidates) either in the Bachelor's Degree(10+2+3 pattern) and/or Master’s Degree in Sciences/Social Sciences/ Humanity,Bachelor’s in Engineering or Technology with specialization in Science and Mathematics with 55%marks or any other qualification equivalent thereto, are eligible for admission to the programme.</p>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>FEE STRUCTURE OF THE COURSE</h3>
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">Particulars</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Amount to be deposited to the university in form of DD</th>
                          <th scope="col">Amount to be deposited to the college at the time of admission in form of DD</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>B.Ed</td>
                          <td>Rs.44,585</td>
                          <td>Rs.34285</td>
                          <td>Rs.10560</td>
                          
                        </tr>
                        
                        <tr>
                          <th scope="row">3</th>
                          <td>Bonafide Fee</td>
                          <td>Rs.100</td>
                          <td>-</td>
                          <td>-</td>
                          
                        </tr>
                        
                        <tr>
                          <th scope="row">6</th>
                          <td>Degree Fee</td>
                          <td>Rs.350</td>
                          <td>-</td>
                          <td>-</td>
                          
                        </tr>
                        <tr>
                          <th scope="row">8</th>
                          <td>Exam Fee</td>
                          <td>As per University</td>
                          <td>-</td>
                          <td>-</td>
                          
                        </tr>
                        <tr>
                          <th scope="row">8</th>
                          <td>Micro/Macro Teaching Book</td>
                          <td>Rs.650</td>
                          <td>-</td>
                          <td>-</td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>Note</h3>
                    <p>• Security will be refunded under the following conditions:</p>
                    <ul>
                      <li>a) Payable without interest after completion of course.</li>
                      <li>b) Subject to clearance of dues from (Labs, Library, Hostel & Transport).</li>
                      <li>c) In case of cancellation or withdrawal due to any reason security amount will be forfeited.</li>
                    </ul>
                    <p>• Exam, Eligibility, Registration, Sports, Digital and Promotion Fee will be charged as per university norms.</p>
                    <p>• Any other fee which is applicable/levied but not covered above shall also be charged.</p>
                    <p>• Fee once paid will not refunded under any circumstances.</p>
                    <p>• Please do not forget to obtain cash receipt after payment of fee.</p>
                    <p>• Fee paid without receipt will not be entertained later on.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feecourse;
