import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faEnvelope,faSquarePhone} from '@fortawesome/free-solid-svg-icons';


function Vision() {
  return (
    <div>
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item">
              <h2>Admission</h2>
              <ul>
                <li>
                  <a href="Default.aspx">Home</a>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                <li>Admission</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
       {/* Location */}
       <div className="location-area">
                <div className="container">
                    <div className="row location-wrap">
                      <h2 className='text-center'>FOR ADMISSION CONTACT US </h2>
                        <div className="col-sm-6 col-lg-4">
                            <div className="location-item">
                            <i className="icofont-ui-call"> <FontAwesomeIcon icon={faLocationDot} /></i>
                                <h3>Location</h3>
                                <p>Kawa Estate Gurah Brahman (Patoli) P. O. Muthi Akhnoor Road Jammu. Pin Code : 181205</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="location-item">
                                <i className="icofont-ui-message"><FontAwesomeIcon icon={faEnvelope} /></i>
                                <h3>Email</h3>
                                <ul>
                                    <li>kawajammu@gmail.com</li>
                                 
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                            <div className="location-item">
                                <i className="icofont-ui-call"><FontAwesomeIcon icon={faSquarePhone} /></i>
                                <h3>Phone</h3>
                                <ul>
                                    <li>+91-9419187853</li>
                                    <li>+91-9086531007</li>
                                    <li>+91-7006153585</li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Location */}


      </div>
  );
}

export default Vision;
