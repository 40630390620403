import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function Cafeteria() {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>Recreation Room</h2>
              
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  
                  <div className="doctor-details-biography">
                    {/* <h3>WHY CHOOSE THIS COURSE?</h3> */}
                    <p>The college emphasis student recreation and well- being to accomplish this, the campus houses, recreation rooms, when the students can spend time playing task tennis, carom, chess and variety of other games.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cafeteria;
