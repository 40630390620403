import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function Service() {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>Our Service</h2>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  
                  <div className="doctor-details-biography">
                    <ul type='dot'>
                        <li><a>CIRCULATION:</a>&nbsp;The library follows an open access policy. You can pick a book of your choice from the shelves and may refer to it in the library or borrow them.</li>
                        <br />
                        <li><a>INTERNET & WIFI FACILITY:</a>&nbsp;The library has 100 computer terminals for internet use. The internet and WIFI service is available in all the libraries.</li>
                        <br />
                        <li><a>SMS/ EMAIL ALERTS:</a>&nbsp;The library sends alert as SMS/EMAIL text messages to take the users about the transaction in the library.</li>
                        <br />
                        <li><a>REPROGRAPHY:</a>&nbsp;The library photocopying service is available from 9:00 A.M – 8:00 P.M. The library supports the copyright policy.</li>
                        <br />
                        <li><a>REFERENCE:</a>&nbsp;The library has a good collection of encyclopaedia’s year books and other reference titles. The reference books cannot be borrowed.</li>
                        <br />
                        <li><a>USER ORIENTATION:</a>&nbsp;The library offers user orientation program to its users on the subsidized resources and other facilities.</li>
                        <br />
                        <li><a>SYMPOSIUM HALL:</a>&nbsp;The library has a symposium hall for its faculty and students. The symposium hall is used for group discussion and presentation by faculty and students.</li>
                        <br />
                        <li><a>JOURNAL ARCHIEVE:</a>&nbsp;The library maintains a good collection of journals and magazine archives in blood volumes.</li>
                        <br />
                        <li><a>REMOTE ACCESS:</a>&nbsp;The library is using e-library portal, which provides all the knowledge available through the library in any device.</li>

                        
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
