import React from 'react';
import '../Assets/Style.css';
import '../Assets/css/animate.css';
import '../Assets/css/bootstrap.min.css';
import '../Assets/css/home-notification.css';
import '../Assets/css/magnific-popup.css';
import '../Assets/css/meanmenu.css';
import '../Assets/css/owl.carousel.min.css';
import '../Assets/css/owl.theme.default.min.css';
import '../Assets/css/responsive.css';
import '../Assets/css/slick.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookMedical, faUserGraduate, faCodeCommit, faPenNib, faSchool, faRoad, faCookieBite, faBookOpen, faBus } from '@fortawesome/free-solid-svg-icons';

const Facilities = () => (
  <>
    {/* Page Title */}
    <div className="page-title-area page-title-four">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="page-title-item">
            <h2>Facilities</h2>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <i className="icofont-simple-right"></i>
              </li>
              <li>Facilities</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    {/* End Page Title */}

    {/* About */}
    <div className="about-area pt-100 pb-70">
      {/* Raji Gandhi Facilities */}
      <section className="facilities-area pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Our Facilities</h2>
          </div>
          <div className="row">
            {facilities.map((facility, index) => (
              <div key={index} className="col-sm-9 col-lg-4 wow fadeInUp mb-4" data-wow-delay={`.${3 + index}s`}>
                <div className="facilities-item">
                  <i className="gh">{facility.icon}</i>
                  <h3>{facility.title}</h3>
                  <p>{facility.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="facilities-area pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row">
            {facilitiessec.map((facilitysec, index) => (
              <div key={index} className="col-sm-9 col-lg-4 wow fadeInUp mb-4" data-wow-delay={`.${3 + index}s`}>
                <div className="facilities-item">
                  <h3>{facilitysec.title}</h3>
                  <p>{facilitysec.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* End Raji Gandhi Facilities */}
    </div>
    {/* End About */}
  </>
);

export default Facilities;

const facilities = [
  {
    icon: <FontAwesomeIcon icon={faCodeCommit} size="2xl" style={{ color: "#0046c0" }} />,
    title: 'Cafeteria/Canteen',
    description: 'KAWA COLLEGE OF EDUCATION has cafeteria and canteen seating up to 100 individuals...'
  },
  {
    icon: <FontAwesomeIcon icon={faUserGraduate} size="2xl" style={{ color: "#0046c0" }} />,
    title: 'Seminar Hall',
    description: 'The campus houses seminar halls, which on variably accommodate 150-200 people...'
  },
  {
    icon: <FontAwesomeIcon icon={faPenNib} size="2xl" style={{ color: "#0046c0" }} />,
    title: 'Recreation Room',
    description: 'The college emphasizes student recreation and well-being to accomplish this...'
  },
  {
    icon: <FontAwesomeIcon icon={faSchool} size="2xl" style={{ color: "#0046c0" }} />,
    title: 'Recreational Areas',
    description: 'The sprawling KAWA COLLEGE OF EDUCATION campus has plenty of nooks and corners...'
  },
  {
    icon: <FontAwesomeIcon icon={faRoad} size="2xl" style={{ color: "#0046c0" }} />,
    title: 'Playground',
    description: 'KAWA COLLEGE OF EDUCATION believes in saying, "All work and no play makes Jack a dull man."...'
  },
  {
    icon: <FontAwesomeIcon icon={faCookieBite} size="2xl" style={{ color: "#0046c0" }} />,
    title: 'Security',
    description: 'Dedicated and disciplined round-the-clock security is the hallmark of the campus...'
  },
  {
    icon: <FontAwesomeIcon icon={faCookieBite} size="2xl" style={{ color: "#0046c0" }} />,
    title: 'Library',
    description: 'WHY CHOOSE THIS COURSE? Databases, E-books, E-journals, Magazines, Journals...'
  }
];

const facilitiessec = [
  {
    title: 'Circulation',
    description: 'The library follows an open access policy. You can pick a book of your choice...'
  },
  {
    title: 'Internet & WiFi Facility',
    description: 'The library has 100 computer terminals for internet use. The internet and WiFi service...'
  },
  {
    title: 'SMS/Email Alerts',
    description: 'The library sends alerts as SMS/EMAIL text messages to take the users about the transaction...'
  },
  {
    title: 'Reprography',
    description: 'The library photocopying service is available from 9:00 AM to 8:00 PM...'
  },
  {
    title: 'User Orientation',
    description: 'The library offers user orientation programs to its users on the subsidized resources...'
  },
  {
    title: 'Symposium Hall',
    description: 'The library has a symposium hall for its faculty and students. The symposium hall...'
  },
  {
    title: 'Journal Archive',
    description: 'The library maintains a good collection of journals and magazine archives in bound volumes...'
  },
  {
    title: 'Remote Access',
    description: 'The library is using an e-library portal, which provides all the knowledge available...'
  }
];
