import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import Chairmanimg from '../../Assets/img/chairman.jpg'

function Chairman() {
  return (
    <>
      {/* Page Title */}
      <div className="page-title-area page-title-three">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>Chairman Message</h2>
              
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="doctor-details-item doctor-details-left">
                <img src={Chairmanimg} alt="Doctor" />
                <div className="doctor-details-contact">
                  <h3>Mr. M.S. KATOCHA</h3>
                  <ul>
                    <li>
                       CHAIRMAN
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                    <h3>FROM THE DESK OF CHAIRMAN</h3>
                    <p>
                    The progress of the institution has its foundation in the grace and blessings of the almighty and the vision and hard work of its leadership and all who were part of laying such a strong foundation. We have completed 21 years as a college and now it is time for journey from good to great. We gear up to move towards our next milestone, towards levelling up on our growth curves and striving to become a great institutionof knowledge.

                    </p>
                    <br />
                    <p>
                    We are here to nurture a culture of curiosity, critical thinking and ethical responsibility and wish to empower our students to become future leaders and change makers. Let us strengthen our research capabilities and foster a vibrant culture of inquiry .let knowledge creation and dissemination be our goal .let our thirst for excellence manifest itself through interdisciplinary collaboration and engagement with academic communities, industries and government.
                    </p>
                    <br />
                    <p>
                    Let us strive to contribute not just to the growth of our institution but to address pressing societal challenges and to become a pioneering institution by being change we wish to see.

I am confident that by capitalising on our strengths, embracing innovation and nurturing a spirit of collaboration.
We will make significant strides in advancing our institution mission and achieving excellence in all domains. 

                    </p>  
                    
                  </div>
                </div>
              </div>
            </div>
            <div>
                      <p>It is my firm belief that by harnessing our collective strengths and by embracing innovation .We can elevate KAWA COLLEGE OF EDUCATION to even greater heights of success and visibility. I look forward to working together with members of institution to create a dynamic and inclusive academic experience.
</p>  
                    <br />
                    <p>I envision, a strong organizational structure, a committed teaching- learning environment, and an impactful research culture.
Together let us embark on journey of growth achievement and positive impact.

Thank you, for your unwavering commitment and dedication to our institution towards a future, filled with limitless possibilities.
</p>    
                  </div>
          </div>
        </div>
      </div>
      {/* End Doctor Details */}
    </>
  );
}

export default Chairman;
