import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import rgipImg from '../../Assets/img/cover/kawalogo.png'; // Update with your actual path


function Header() {
  return (
    <>
      {/* Header Top */}
      <div className="header-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-10 col-lg-10">
              <div className='col-md-2'>
                <div className="header-top-notify">
                  <div className="header-notify">
                   <p>NOTIFICATION</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-2 col-lg-2">
              <div className="header-top-item">
                <div className="header-top-right">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/rgcnjammuofficial" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/JammuRgcn" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/rgcnjammu" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCxVIoBj_3ILZQkyB1z8uxtQ" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Header Top */}
      <div className='row'>
        <div className="col-md-3 pb-2 text-center">
          <img src={rgipImg} alt="Logo" className="img-fluid retro-logo" />
        </div>
        <div className="col-md-8 retro-title">
          <p>KAWA COLLEGE OF EDUCATION</p>
        </div>
      </div>
    </>
  );
}

export default Header;
