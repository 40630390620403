import React from 'react';
import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlack} from '@fortawesome/free-brands-svg-icons';

const RulesAndRegulations = () => {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item">
              <h2>Rules and regulations</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                <li>Rules and regulations</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy Section */}
      <section className="privacy-area pt-100">
        <div className="container">
          
          <div className="privacy-item">
            <h2>HOSTEL RULES</h2>
            <ul>
            <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;Hostel facilities for female students have been provided by the college management. The girl’s hostel is located within the campus Hostel is equipped with modern equipment which includes fully ventilated rooms, cots, good dining hall with appropriate crockery and utensils etc. It is advisable that the non-local female students should stay in the hostel so that they could study seriously. A form of admission in the hostel is available with the warden/ hostel incharge/office which is to filled by the hostler. The rent charges for the hostel are fixed by the college. There will be separate charges for the meals. The following rules and regulations framed by the Management Committee are strictly followed by the hostellers.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;Cleanliness of the hostel rooms and campus be maintained.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;Toilets and bathrooms to be used fairly. Any blockade can cause serious trouble in inmates.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;No relatives of the inmates shall be allowed to stay in the hostel even for one night.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;No inmates shall be allowed to stay overnight to his /her guardian without prior permission of the hostel /warden /principal.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} /> &nbsp;No outside phone calls will be entertained after 9.30p.m. except from the parents /close relations whose identity is recorded in the hostel register.</li>
              <li> <FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;Inmates shall seek proper permission for leaving the hostel from warden /principal when they go to their homes. They will also inform the warden / principal on phone about their safe arrival.</li>
              <li> <FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;Any kind of uncultured behaviors on the part of hostlers will not be tolerated and the parents will be informed about it.</li>
              <ul> <FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;The meals will be served as per the following timings
                <li>Breakfast 8.15a.m. to 9.45 a.m.</li>
                <li>Lunch 1.40 p.m. to 2.10 p.m.</li>
                <li>Evening Tea 4.30p.m. to 5.00 p.m.</li>
                <li>Dinner  8.00 pm to 9.00 pm</li>
              </ul>
            </ul>
          </div>

          <div className="privacy-item">
            <h2>LIBRARY RULES</h2>
            <ul>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;Silence should be observed strictly by the readers.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;No outsider is allowed to enter in the library without the prior permission of the principal / Librarian.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;The holder of the library card is entitled to draw any two books of any subject from the library at a time.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;The books will be issued to the students for one week. Fine of Rs. 5/- per day will be charged on each book kept overtime by the students.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp; The reference books / journals / Magazines will not be issued even for overnight to any night.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;The students are advised strictly not to deface any newspaper, book magazine and journals. They are also suggested not to write anything on books, study tables or chairs. If any student is detected doing so, he will be fined accordingly.</li>
              <li><FontAwesomeIcon icon={faSlack} style={{color: "#ff5050",}} />&nbsp;All the students will pay an amount of Rs. 500/-as library security which will be refunded after the declaration of the final result. All the students have to follow the rules, otherwise they will face the consequences.</li>
            
            </ul>
          </div>

        </div>
      </section>
    </div>
  );
};

export default RulesAndRegulations;
