import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Component/Pages/Views/Navbar';
import Home from './Component/Pages/Home';
import Introducation from './Component/Pages/Views/Introducation';
import Vision from "./Component/Pages/Views/vision";
import Chairman from './Component/Pages/Views/Chairman';
import Director from './Component/Pages/Views/Director';
import Principal from './Component/Pages/Views/Principal';
import Rulesregulations from './Component/Pages/Views/Rules-regulations';
import Grievance from './Component/Pages/Views/Grievance';
import Service from './Component/Pages/Views/Service';
import Facilities from './Component/Pages/Facilities';
import Cafeteria from './Component/Pages/Views/Cafeteria';
import Seminar from './Component/Pages/Views/Seminar';
import Recreationroom from './Component/Pages/Views/Recreation_room'
import Recreational from './Component/Pages/Views/Recreationalarea'
import Playground from './Component/Pages/Views/Playground'
import Security from './Component/Pages/Views/Security'
import Application from './Component/Pages/Views/Application';
import Gallery from './Component/Pages/Gallery';
import Contact from './Component/Pages/Contact';
import Dpharmacy from './Component/Pages/Views/D-pharmacy';
import Bpharmacy from './Component/Pages/Views/B-pharmacy';
import Library from './Component/Pages/Views/Library';
import Alumni from './Component/Pages/Views/Alumni';
import Header from './Component/Pages/Views/Header';
import Footer from './Component/Pages/Views/Footer';
import Nep from './Component/Pages/Views/Nep';
import Admission from './Component/Pages/Views/admission';




function App() {
  return (
    <Router>
      <div>
        <Header />
        <Navbar />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/introducation" element={<Introducation/>} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/chairman" element={<Chairman />} />
          <Route path="/director" element={<Director />} />
          <Route path="/principal" element={<Principal />} />
          <Route path="/chairman" element={<Chairman />} />
          <Route path="/Rules-regulations" element={<Rulesregulations />} />
          <Route path="/grievance" element={<Grievance />} />
          <Route path='/Service' element={<Service />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="/Cafeteria" element={<Cafeteria />} />
          <Route path="/Seminar" element={<Seminar />} />
           <Route path="/Recreationroom" element={<Recreationroom />} />
           <Route path="/Recreational" element={<Recreational />} />
           <Route path="/Playground" element={<Playground />} />
           <Route path="/Security" element={<Security />} />
          <Route path="D-pharmacy" element={<Dpharmacy />} />
          <Route path="Admission" element={<Admission />} />
          <Route path="B-pharmacy" element={<Bpharmacy />} />
          <Route path="Library" element={<Library />} />
          <Route path="application" element={<Application />} />
          <Route path="alumni" element={<Alumni />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/Nep" element={<Nep />} />
          <Route path="/" element={<Home />} />
          
        </Routes>
        <Footer />
      </div>
    </Router>
    
  );
}

export default App;
