import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function Library() {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>LIBRARY</h2>
              
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  
                  <div className="doctor-details-biography">
                    <h3>WHY CHOOSE THIS COURSE?</h3>
                    <ul>
                      <li> <FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Databases</li>
                      <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; E-books</li>
                      <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; E- journals</li>
                      <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Magazines</li>
                      <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Journals</li>
                      <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Open access materials</li>
                      <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Writing tools</li>
                      <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Institutional membership</li>
                      <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Academic e-book</li>
                      <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Question papers</li>
                    </ul>
                  </div>
                  <div className="doctor-details-biography">
                  <h3>OUR SERVICES</h3>
                    <ul type='dot'>
                        <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; <a>CIRCULATION:</a>&nbsp;The library follows an open access policy. You can pick a book of your choice from the shelves and may refer to it in the library or borrow them.</li>
                        
                        <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; <a>INTERNET & WIFI FACILITY:</a>&nbsp;The library has 100 computer terminals for internet use. The internet and WIFI service is available in all the libraries.</li>
                       
                        <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; <a>SMS/ EMAIL ALERTS:</a>&nbsp;The library sends alert as SMS/EMAIL text messages to take the users about the transaction in the library.</li>
                        
                        <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; <a>REPROGRAPHY:</a>&nbsp;The library photocopying service is available from 9:00 A.M – 8:00 P.M. The library supports the copyright policy.</li>
                       
                        <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; <a>REFERENCE:</a>&nbsp;The library has a good collection of encyclopaedia’s year books and other reference titles. The reference books cannot be borrowed.</li>
                       
                        <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; <a>USER ORIENTATION:</a>&nbsp;The library offers user orientation program to its users on the subsidized resources and other facilities.</li>
                       
                        <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; <a>SYMPOSIUM HALL:</a>&nbsp;The library has a symposium hall for its faculty and students. The symposium hall is used for group discussion and presentation by faculty and students.</li>
                       
                        <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; <a>JOURNAL ARCHIEVE:</a>&nbsp;The library maintains a good collection of journals and magazine archives in blood volumes.</li>
                      
                        <li><FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; <a>REMOTE ACCESS:</a>&nbsp;The library is using e-library portal, which provides all the knowledge available through the library in any device.</li>

                        
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Library;
