import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import JUlogo from '../../Assets/img/cover/JULOGO.png'
import UGClogo from '../../Assets/img/cover/UGCLogo.png'
import nctelogo from '../../Assets/img/cover/NCTE-Logo.jpg'

function Affiliated() {
  return (
    <>
      <div className='affiliation'>
        <div className="about-area pt-100 pb-100">
          <div className='container'>
            <div className="row align-items-center">
                <h2 className='text-center fw-bold pb-80'>COLLEGE IS AFFILIATED BY</h2>
              <div className='d-flex justify-content-center'>
                <div className='col-md-4 text-center' >
                  <img src={JUlogo}  className='affilationlogo'/>
                  <h3 className='affilation-text p-2'>UNIVERSITY OF JAMMU</h3>
                </div>
                <div className='col-md-4 text-center'>
                  <img src={UGClogo} className='affilationlogo'/>
                  <h3 className='affilation-text p-2'>UNIVERSITY GRANTS COMMISSION</h3>
                </div>
                <div className='col-md-4 text-center'>
                  <img src={nctelogo} className='affilationlogo'/>
                  <h3 className='affilation-text p-2'>NATIONAL COUNCIL FOR TEACHER EDUCATION</h3>
                </div>
              </div>
            </div>
          </div>

        </div>
        </div>
    </>
  );
}

export default Affiliated;
