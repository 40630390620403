import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function Library() {
  return (
    <div>
      {/* Page Title */}
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>National Education Policy 2020</h2>
              
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  
                  <div className="doctor-details-biography">
                    <h3>NEP AT KAWA COLLEGE OF EDUCATION</h3>
                    <p>NEP 2020, with reference to the highest education scenari, fosters a positive change in the way knowledge must be cultivated among the youth of the nation. The programme structure and course outcomes are mapped out to address the learning needs of the current times. NEP focuses on providing learners with multiple course combinations with no hard separation of stream and subjects. A student majoring in a science course may take up another subject from another stream or take a multidisciplinary course and benefit from such fluidity.such freedom to explore knowledge across different domains is the greatest advantage of the policy. The primary focus of the NEP is in increasing the critical thinking skills and cognitive abilities of young INDIA.</p>
                    <p>NEP aspires to create the ancient culture of learning by offering a multidisciplinary engagement and bolstering the existing pedagogical practise to integrate classical approach with a modern outlook. Logic reasoning, didactic thinking, rational debates, abstraction, ethical decision making and problem solving approach would be the way forward in the classrooms. The visions of NEP inspires and encourages the teachers and learners to explore new ideas and thoughts beyond the boundaries of a linear ‘’knowledge system. </p>
                    <p>In this light, the multidisciplinary courses, are offered to stimulate innovative thinking and critical problem solving skills. Breaking free from the single stream, single degree form of education. The NEP provides learners with variety of subject combinations, thereby allowing newer discourses to emerge from such inter-disciplinary and multi-disciplinary approach.
                        Another key highlight of the policy is the GCED,(Global citizenship education) that aims to promote awareness of global issues to empower learners towards becoming confident citizen. Providing learners with skills required on an international platform and with exposure to the needs around the world learners will become active citizens supporting a sustainable development of the country.
                        </p>
                  </div>
                  <div className="doctor-details-biography">
                    <h3>KEY FEATURES OF NEP – IMPLEMENTATION AT KAWA COLLEGE OF EDUCATION.</h3>
                    <ul>
                        <li> <FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Multidisciplinary education</li>
                        <li> <FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Technology –enabled education</li>
                        <li> <FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Modern library resources</li>
                        <li> <FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Global exposure and exchange programs</li>
                        <li> <FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Holistic education approach</li>
                        <li> <FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Inclusive academic environment</li>
                        <li> <FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; State of the art research facilities</li>
                        <li> <FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Continuous infrastructure development</li>
                        <li> <FontAwesomeIcon icon={faArrowRight} flip="vertical" style={{color: "#e98074",}} /> &nbsp; Community engagement</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Library;
