import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import Chairmanimg from '../../Assets/img/director.jpg'

function Chairman() {
  return (
    <>
      {/* Page Title */}
      <div className="page-title-area page-title-three">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>Director Message</h2>
              
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="doctor-details-item doctor-details-left">
                <img src={Chairmanimg} alt="Doctor" />
                <div className="doctor-details-contact">
                  <h3>Mr. Abhimanyu KATOCH</h3>
                  <ul>
                    <li>
                       Director
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                    <h3>FROM THE DESK OF Director</h3>
                    <p>
                    It is always delight to welcome all of you to KAWA COLLEGE OF EDUCATION.
                    </p>
                    <br/>
                    <p>
                    I cherish this time of the year when admission are in process and the campus is  poised to welcome a new batch of vibrant and 
                    vivacious students who will bring with them the newer ideas, sustainable plans and ground breaking projects.
                    </p>
                   <br/>
                    <p>
                    The combination of fresh ideas are verge is what has always imbued our enthusiasm to work towards realizing our vision of 
                    “EXCELLENCE AND SERVICE” 
                    </p>
                    <br/>
                    <p>
                    KAWA COLLEGE OF EDUCATION is driven by the vision, mission and core values that students have assimilated as a habit. We are convinced now more than ever, 
                    when we are celebrating 30 years of our existence doing yeoman service to JAMMU AND KASHMIR education that our aspiration to becoming a best college is 
                    indeed achievable when there is a deep sense of identity as student of KAWA COLLEGE OF EDUCATION, when our personnel, societal and academic involvement 
                    is perfectly balanced and engaging contributing to our growth and that of the communication we are part of and our local and global understanding is based  
                    on traditions and culture and responds to world-wide needs and concerns ,we are well on our way to success.
                    </p>
                    
                    
                    
                    
                
                    
                  </div>
                  
                </div>
                
              </div>
              
            </div>
            
                  
                    <br />
                    <p>
                    At, KAWA COLLEGE OF EDUCATION, holistic training is of paramount importance. We believe that education here will prepare you for a highly networked and 
                    competitive world. The whole person training facilitated here will support your interests, encourage you to develop your informed perspectives, inculcate
                     managerial and administrative skills and develop relevant pedagogical and research methods.
                    </p>
            <div>
                     
                  </div>
          </div>
        </div>
      </div>
      {/* End Doctor Details */}
    </>
  );
}

export default Chairman;
