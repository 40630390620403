import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

import Chairmanimg from '../../Assets/img/principaljk.jpg'

function Chairman() {
  return (
    <>
      {/* Page Title */}
      <div className="page-title-area page-title-three">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item-two">
              <h2>Principal Message</h2>
              
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title */}

      {/* Doctor Details */}
      <div className="doctor-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="doctor-details-item doctor-details-left">
                <img src={Chairmanimg} alt="Doctor" />
                <div className="doctor-details-contact">
                  <h3>Dr. Bharti Sharma</h3>
                  <ul>
                    <li>
                       Principal
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="doctor-details-item">
                <div className="doctor-details-right">
                  <div className="doctor-details-biography">
                    <h3>FROM THE DESK OF Principal</h3>
                    <p>
                    At KAWA COLLEGE OF EDUCATION, holistic training is of paramount importance. We believe that education here will prepare you for a 
                    highly networked and competitive world. The whole person training facilitated here will support your interests, encourage you to
                     develop your own informed perspectives, inculcate managerial and administrative skills and develop relevant pedagogical and 
                     research methods.
                    </p>
                    <br />
                    <p>
                    In short, we transfer you to diligent students, smart team players, ethical leaders and conscientious citizen. Becoming a student 
                    of KAWA COLLEGE OF EDUCATION. You should be keen observer and identify opportunities that will benefit you and help you grow, as 
                    students offers you plenty of choices and chances .Have the confidence to be a good team player, have shared vision for attaining 
                    your goals which should be in tandem with that of the institutions, have a productive relationship with other stake holders of the
                     college and be discerning in your choices and decisions.
                    </p>
                
                    
                  </div>
                </div>
              </div>
            </div>
            <div>
                     
                  </div>
          </div>
        </div>
      </div>
      {/* End Doctor Details */}
    </>
  );
}

export default Chairman;
