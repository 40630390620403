import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'


function Vision() {
  return (
    <div>
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item">
              <h2>Vision and Mission</h2>
              <ul>
                <li>
                  <a href="Default.aspx">Home</a>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                <li>Vision and Mission</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="about-area pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center border shadow-lg p-3 mb-5 bg-white rounded ">
            <div className="col-lg-2">
              <div className="about-item">
                <h2>VISION</h2>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="about-item about-right">
                <ul> 
                  <li>Leading to improve the world around us. Since our inception, we have believed in education for all leadership that improves lines in all of the communities that we serve.</li>
                  <li>Excellence service.</li>
                  <li>To learn and educate wholly– developed educators and citizen for a sustainable future.</li>
                </ul>
              </div>
            </div>
            
          </div>
            <div className="row align-items-center border shadow-lg p-3 mb-5 bg-white rounded ">
                <div className="col-lg-2">
                <div className="about-item ">
                    <h2>MISSION</h2>
                </div>
                </div>
                <div className="col-lg-9">
                  <div className="about-item about-right">
                      <ul>
                        <li>KAWA COLLEGE OF EDUCATION is a nurturing ground of an individual’s holistic development to make effective contribution to the society in a dynamic environment.</li>
                        <li>KAWA COLLEGE OF EDUCATION is an excellent choice for aspiring educationalist to be assets to society in a dynamic environment. </li>
                        <li>To prepare educators who are knowledgeable, service –minded, and competent.</li>
                        <li>To foster researches who brings about positive change in education and society.</li>
                        <li>To nurture well-rounded professionals who are adaptable and life –long learners.</li>
                      </ul>
                  </div>
              </div> 
            {/* </div> */}
            {/* <div className="row align-items-center border shadow-lg p-3 mb-5 bg-white rounded ">
                <div className="col-lg-2">
                <div className="about-item">
                    <h2>GOALS</h2>
                </div>
                </div>
                <div className="col-lg-9">
                <div className="about-item about-right">
                  <p>
                  To excel in various disciplines of education. To encourage research and development. In various
departments in thrust areas identified by various national agencies. Our values and attributes,
which align with our guiding statements, are integral to create a college culture and climate to
realise our educational goals.
                </p>
                </div>
                </div>  */}
                
            </div>
            <div className="row align-items-center border shadow-lg p-3 mb-5 bg-white rounded ">
            <div className="col-lg-2 mr-2">
            <div className="about-item ">
                    <h2>ATTRIBUTES</h2>
                </div>
                </div>
                <div className="col-lg-9">
                  <div className="about-item about-right">
                  <p>KAWA COLLEGE OF EDUCATION  focus on holistic  development  or whole person growth, the holistic development of students is attained  through academic excellence, professional competence, personal and interpersonal and society skills. Every pass out of college is desired  to possess the following attributes. The college actualizes the students through curricula, courses on personal, interpersonal and societal skills.</p>
                  <div className="doctor-details-biography">
                    <h4>ACADEMIC THEME</h4>
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">ATTRIBUTES</th>
                          <th scope="col">INDICATORS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>ACADEMIC EXCELLENCE</td>
                          <td>Extensive knowledge in the chosen discipline with ability to apply it effectively.</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>DOMAIN EXPERTISE</td>
                          <td>Comprehensive specialist knowledge of the field of study and defined professional skills ensuring work readiness.</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>PROBLEM SOLVING SKILLS</td>
                          <td>Making informed choices in a  variety of situations ,useful in scholarly context that enables the students to understand and develop solutions.</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>KNOWLEDGE APPLICATION</td>
                          <td>Ability to use available knowledge to make decisions and perform task.</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>SELF – LEARNING AND RESEARCH SKILLS</td>
                          <td>Ability  to create a new understanding and knowledge through the process of research and inquiry .</td>
                        </tr>
                        
                        
                      </tbody>
                    </table>
                  </div>
                </div>
                </div> 
                
            </div>
            <div className="row align-items-center border shadow-lg p-3 mb-5 bg-white rounded ">
            <div className="col-lg-2 mr-2">
            <div className="about-item ">
                    <h2>CORE VALUES</h2>
                </div>
                </div>
                <div className="col-lg-9">
                  <div className="about-item about-right">
                  
                <ul type='.'>
                  <li>MORAL UPRIGHTNESS</li>
                  <li>LOVE OF FELLOW BEINGS</li>
                  <li>SOCIAL RESPONSIBILITY</li>
                  <li>PURSUIT OF EXCELLENCE</li>
                  
                </ul>
                </div>
                </div> 
                
            </div>
            {/* <h4 className='rounded-pill shadow-lg p-4 mb-5 bg-white rounded '> OUR MAIN OBJECTIVE IS TO PROVIDE A SAFE, SECURE AND CONDUCIVE
ENVIRONMENT IN WHICH EVERY STUDENT CAN DARE TO DREAM, ENJOY LEARNING,
ACHIEVE EXCELLENCE AND BECOME A LIFE LONG LEARNER.</h4> */}
        </div>
      </div>
    </div>
  );
}

export default Vision;
