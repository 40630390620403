import React from 'react';
import '../Assets/Style.css';
import Slider from './Views/Slider';
import Affiliated from './Views/Affiliated';
import '../Assets/css/animate.css';
import '../Assets/css/bootstrap.min.css';
import '../Assets/css/home-notification.css';
// import '../Assets/css/icofont.css';
import '../Assets/css/magnific-popup.css';
import '../Assets/css/meanmenu.css';
import '../Assets/css/owl.carousel.min.css';
import '../Assets/css/owl.theme.default.min.css';
import '../Assets/css/responsive.css';
import '../Assets/css/slick.min.css';

import expertiseimg from '../Assets/img/cover/home-buses.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrescriptionBottleMedical,faBookMedical,faBookOpenReader,faUserGraduate,faCalendarDays, faPersonChalkboard,faBuilding, faComputer, faPeopleRoof} from '@fortawesome/free-solid-svg-icons';
// import '../Assets/css/slick-theme.css';
// import '../Assets/RetroStyle.css';
import JUlogo from '../Assets/img/cover/JULOGO.png';
import UGClogo from '../Assets/img/cover/UGCLogo.png';
import photo1 from '../Assets/img/slider/students.jpg'
import CardSliderComponent from '../Pages/Views/sliderFaci';


function Home() {
  return (
    <div>
      <Slider />
      {/* <Affiliated /> */}
      <div className='home-body'>
        <div className="about-area pt-100 pb-70">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="about-item about-right">
                  {/* <img src="assets/img/home-one/5.png" alt="About" /> */}
                  <h2>About Our Institution</h2>
                  <p>
                    The BACHELOR OF EDUCATION (B. Ed) offered by KAWA COLLEGE OF EDUCATION is designed to nurture the educators of tomorrow. This full 
                    -time program is delivered face to face and spans two enriching academic years across from 4 semesters, preparing students to take 
                    on roles in teaching and other fields. The BACHELOR OF EDUCATION (B. Ed) program Holds recognitions from Govt. of J&K and is 
                    affiliated from university of Jammu, a testament to its quality and adherence to national standards. This accreditation assures 
                    students and stake holders that they are engaging with a program of highest caliber.
                  </p>
                  <a href="/Introducation">Know More</a>
                </div>
              </div>
              <div className="col-lg-5 notification-panel">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <i className="icofont-notification"></i>
                    <b>Latest Notifications / Announcement & Results</b>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-xs-12">
                        <ul className="demo1">
                          {/* Add your conditional rendering logic for notifications here */}
                          <h1>Sorry No Data Found</h1>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="panel-footer">
                    <ul className="pagination pull-right" style={{ margin: '0px' }}>
                      <li><a href="#" className="prev"><i className="fa fa-chevron-down" aria-hidden="true"></i></a></li>
                      <li><a href="#" className="next"><i className="fa fa-chevron-up" aria-hidden="true"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="expertise-area pt-110 pb-70">
          <div className="container">
            <div className="section-title">
              <h2>Courses offered</h2>
            </div>
            <div className="row  align-items-center">
              <div  className="col-lg-6">
                <div className='course-body'>
                  <h3>Bachelors of Education(B.Ed)</h3>
                  <br />
                  <p>
                    We offers Bachelors of Education (B. Ed) which is under graduate academic degree which prepares students towards the professional
                     outlook of teaching. It is a 2 years duration course. Students are admitted through entrance examination (CUET) WITH AN INTAKE 
                     CAPACITY OF 100 STUDENTS. The BACHELOR OF EDUCATION (B. Ed) program combines theory and practice, personal and professional growth
                      and local and global perspectives to shape educators who are ready to make meaningful impact.
                  </p>
                  </div>
              </div>
              <div className="col-lg-6 ">
                <img className='course-img' src={photo1}/>
              </div>
            </div>
          </div>
        </section>
        <section className="services-area pt-110 pb-70">
          <div className="container">
            <div className="section-title">
              <h2>Our Facilities</h2>
            </div>
            <div className="row">
            <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div className="service-item border border-primary">
                  <div className="service-front">
                    <FontAwesomeIcon icon={faUserGraduate} size="2xl" style={{color: "#0046c0",}}  />
                    <h3>Seminar Hall</h3>
                    <p>The campus house seminar halls, which on variably accommodate 150-200 people at time. The facilities cater to the efficient organisation of on campus such as meetings, convocation, guest lectures, presentations short term training programs, workshops, conferences, cultural activities, celebrations and a lot more.</p>
                  </div>
                  <div className="service-end">
                    <FontAwesomeIcon icon={faUserGraduate} size="2xl" style={{color: "#f8f7f7",}} />
                    <h3>Seminar Hall</h3>
                    <p>The campus houses seminar halls, which on variably accommodate 50-100 people at time. The facilities cater to the efficient organisation of on campus such as meetings, convocation, guest lectures, presentations short term training programs, workshops, conferences, cultural activities, celebrations and a lot more.</p>
                    <a href="/Facilities">Contact Us</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div className="service-item border border-primary">
                  <div className="service-front">
                    <FontAwesomeIcon icon={faUserGraduate} size="2xl" style={{color: "#0046c0",}}  />
                    <h3>Library</h3>
                    <ul className='text-left'>
                      <li>Databases</li>
                      <li>E- journals</li>
                      <li> Magazines</li>
                      <li> Institutional membership</li>
                      <li>Writing tools</li>
                      <li>Academic e-book</li>
                      <li> E-books</li>
                      <li>Open access materials</li>
                      <li>Journals</li>
                    </ul>
                  </div>
                  <div className="service-end">
                    <FontAwesomeIcon icon={faUserGraduate} size="2xl" style={{color: "#f8f7f7",}} />
                    <h3>Library</h3>
                    <ul>
                      <li>Databases</li>
                      <li>E- journals</li>
                      <li> Magazines</li>
                      <li> Institutional membership</li>
                      <li>Writing tools</li>
                      <li>Academic e-book</li>
                      <li> E-books</li>
                      <li>Open access materials</li>
                      <li>Journals</li>
                    </ul>
                    <a href="/Facilities">Contact Us</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div className="service-item border border-primary">
                  <div className="service-front">
                  <FontAwesomeIcon icon={faBookOpenReader} size="2xl" style={{color: "#0046c0",}} />
                    <h3>Playground</h3>
                    <p>KAWA COLLEGE OF EDUCATION believes in saying. Since whole person development is what the college strives for, extra-curricular activities are as important asa academic activation , there are activities for both indoor and outdoor sports in all campuses. League competitors and tournaments are organized throughout the year on the campus.</p>
                  </div>
                  <div className="service-end" size="2xl" style={{color: "#f8f7f7",}}>
                  <FontAwesomeIcon icon={faBookOpenReader} />
                    <h3>Playground</h3>
                    <p>KAWA COLLEGE OF EDUCATION believes in saying. Since whole person development is what the college strives for, extra-curricular activities are as important asa academic activation , there are activities for both indoor and outdoor sports in all campuses. League competitors and tournaments are organized throughout the year on the campus.</p>
                    <a href="/Facilities">Contact Us</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div className="service-item border border-primary">
                  <div className="service-front">
                  <FontAwesomeIcon icon={faCalendarDays}  size="2xl" style={{color: "#0046c0",}}/>
                    <h3>Security</h3>
                    <p>Dedicated and disciplined round the clock security is the hallmark of the campus. They are disciplined,polite and at the same time duty bound to strict rules and regulations, which they enforce on campus. They are the custodians of peace and security on campuses and the student with their mental and physical presence in their posts.</p>
                  </div>
                  <div className="service-end">
                  <FontAwesomeIcon icon={faCalendarDays}  size="2xl" style={{color: "#f8f7f7",}}/>
                    <h3>Security</h3>
                    <p>Dedicated and disciplined round the clock security is the hallmark of the campus. They are disciplined,polite and at the same time duty bound to strict rules and regulations, which they enforce on campus. They are the custodians of peace and security on campuses and the student with their mental and physical presence in their posts.</p>
                    <a href="/Facilities">Contact Us</a>
                  </div>
                </div>
              </div> 
            </div>
            {/* <div className="">
             <CardSliderComponent />
            </div> */}
          </div>
        </section>
        {/* <section className="expertise-area pb-70">
          <div className="container">
            <div className="section-title">
              <h2>Our Expertise</h2>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="expertise-item">
                  <div className="row">
                    <div className="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                      <a href="javascript:void(0)">
                        <div className="expertise-inner">
                          <i className="icofont-doctor-alt"> <FontAwesomeIcon icon={faPersonChalkboard} /></i>
                          <a href="teacherlist"><h3>Certified Teachers</h3></a>
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                      <a href="javascript:void(0)">
                        <div className="expertise-inner">
                          <i className="icofont-building"><FontAwesomeIcon icon={faBuilding} /></i>
                          <h3>Well Infrastructure</h3>
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                      <a href="javascript:void(0)">
                        <div className="expertise-inner">
                          <i className="icofont-network"><FontAwesomeIcon icon={faComputer} /></i>
                          <h3>Smart Classes</h3>
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                      <a href="javascript:void(0)">
                        <div className="expertise-inner">
                          <i className="icofont-mic"><FontAwesomeIcon icon={faPeopleRoof} /></i>
                          <h3>Seminar Room</h3>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="expertise-item">
                  <div className="expertise-right">
                    <img src={expertiseimg} alt="Expertise" />
                  </div>
                </div>
              </div>
            </div> */}
          {/* </div>
        </section> */}
        {/* <Affiliated /> */}
      </div>
    </div>
  );
}

export default Home;
