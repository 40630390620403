import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import your images here
import image1 from '../Assets/img/gallery/gallery1.jpg'
import image2 from '../Assets/img/gallery/gallery2.jpg'
import image3 from '../Assets/img/gallery/gallery3.jpg'
import image4 from '../Assets/img/gallery/gallery4.jpg'
import image5 from '../Assets/img/gallery/gallery5.jpg'
import image6 from '../Assets/img/gallery/gallery6.jpg'
import image7 from '../Assets/img/gallery/students.jpg'
import image8 from '../Assets/img/gallery/gallery8.jpg'
import image9 from '../Assets/img/gallery/gallery9.jpg'
import image10 from '../Assets/img/gallery/gallery10.jpg'
import image11 from '../Assets/img/gallery/gallery11.jpg'
import image12 from '../Assets/img/gallery/gallery12.jpg'
const images = [
  {
    src: image1,
    alt: 'Image 1',
    // title: 'Sunset',
    // description: 'A beautiful sunset ovezxfgxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxr the mountains.'
  },
  {
    src: image2,
    alt: 'Image 2',
    // title: 'Beach View',
    // description: 'Enjoying a relaxing day at the beach.'
  },
  {
    src: image3,
    alt: 'Image 3',
    // title: 'Cityscape',
    // description: 'A stunning view of the city skyline at night.'
  },
  {
    src: image4,
    alt: 'Image 4',
    // title: 'Nature Trail',
    // description: 'Exploring the lush greenery on a nature trail.'
  },
  {
    src: image5,
    alt: 'Image 5',
    // title: 'Waterfall',
    // description: 'Standing in awe of the majestic waterfall.'
  },
  {
    src: image6,
    alt: 'Image 6',
  //   title: 'Mountain Peak',
  //   description: 'Reaching the summit of the highest peak.'
  },
  {
    src: image7,
    alt: 'Image 7',
  //   title: 'Mountain Peak',
  //   description: 'Reaching the summit of the highest peak.'
  },
  {
    src: image8,
    alt: 'Image 8',
  //   title: 'Mountain Peak',
  //   description: 'Reaching the summit of the highest peak.'
  },
  {
    src: image9,
    alt: 'Image 9',
  //   title: 'Mountain Peak',
  //   description: 'Reaching the summit of the highest peak.'
  },
  {
    src: image10,
    alt: 'Image 10',
  //   title: 'Mountain Peak',
  //   description: 'Reaching the summit of the highest peak.'
  },
  {
    src: image11,
    alt: 'Image 11',
  //   title: 'Mountain Peak',
  //   description: 'Reaching the summit of the highest peak.'
  },
  {
    src: image12,
    alt: 'Image 12',
  //   title: 'Mountain Peak',
  //   description: 'Reaching the summit of the highest peak.'
  }
];

function Gallery() {
  return (
    <div className="container mt-3">
      <div className="row">
        {images.map((image, index) => (
          <div className="col-lg-4 col-md-6 mb-4" key={index}>
            <div className="card h-100">
              <img className="card-img-top" src={image.src} alt={image.alt} />
              <div className="card-body">
                <h5 className="card-title">{image.title}</h5>
                <p className="card-text">{image.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
