import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import '../../Assets/Style.css';
import '../../Assets/css/animate.css'
import '../../Assets/css/bootstrap.min.css'
import '../../Assets/css/home-notification.css'
// import '../Assets/css/icofont.css'
import '../../Assets/css/magnific-popup.css'
import '../../Assets/css/meanmenu.css'
import '../../Assets/css/owl.carousel.min.css'
// import '../Assets/css/owl.theme.default.min.css'
 import '../../Assets/css/responsive.css'
import '../../Assets/css/slick.min.css'

function Introducation() {
  return (
    <div>
      <div className="page-title-area page-title-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="page-title-item">
              <h2>About</h2>
              <ul>
                <li>
                  <a href="Default.aspx">Home</a>
                </li>
                <li>
                  <i className="icofont-simple-right"></i>
                </li>
                <li>About</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="about-area pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center">
            {/* <div className="col-lg-6">
              <div className="about-item">
                <div className="about-left">
                  <img src="imgs/home-about.jpg" alt="About" />
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-6"> */}
              <div className="about-item about-right">
                {/* <img src="assets/img/home-one/5.png" alt="About" /> */}
                <h2>OVERVIEW</h2>
                {/* <h5>(Recognized by Pharmacy Council of India (PCI)-New Delhi) (Approved/Affiliated with J & K Govt. Paramedical Council Jammu J & K)</h5> */}
                <p>
                  The BACHELOR OF EDUCATION (B. Ed) offered by KAWA COLLEGE OF EDUCATION is designed to nurture the educators of tomorrow. This full 
                  -time program is delivered face to face and spans two enriching academic years across from semesters, preparing students to take on
                   roles in teaching and other fields. The BACHELOR OF EDUCATION (B.ED) program Holds recognitions from Govt. of J&K and is affiliated
                    from university of Jammu, a testament to its quality and adherence to national standards. This accreditation assures students and 
                    stake holders that they are engaging with a program of highest caliber. At KAWA COLLEGE OF EDUCATION, We are dedicated to producing 
                    educators who are not only academically proficient but also socially responsible and globally aware.
                </p>
                
                <p>
                  One of the cornerstones of the BACHELOR OF EDUCATION (B. Ed) program is the immersive internship experiences. Pre-service teachers will have the 
                  unique opportunity to step into real classrooms, observe and apply their knowledge and skills with the guidance of experienced school practitioners. 
                  This hands on experience is invaluable and prepares them for the demands of the teaching profession.
                </p>
                
                <p>We believe that educators should be emotionally intelligent and socially aware thus , we are committed to fostering holistic 
                  development in our students. Beyond the core courses prescribed, our program integrates enhancement courses that focus on 
                  personal well-being and hones employability, entrepreneurial, technological and sustainability skills to name a few. Education 
                  extends beyond the classroom walls. Our (B.Ed) program emphasizes community and service – learning, encouraging students to 
                  connect with local communities and apply their skills to real world situations. By doing so, they become a catalyst for positive 
                  changes. Understanding the concept of giving back to their communities, resulting in societal progress. Students  who aspire to
                   broaden their horizons. This qualification is hallmark of excellence in education and opens doors to a teaching career. This 
                   serves as a testament to providing education.</p>

                <p>Join us in our journey to nurture future generations of transformative educators who work towards making a better world. Your 
                  path to excellence starts here.</p>
              </div>
             
            {/* </div> */}
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introducation;
